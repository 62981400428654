import {
  UTM_PARAMS,
  UTM_SESSION_STORAGE_KEY,
  type UtmDataType,
} from '../../defs';

export function setUtmSessionStorage(searchParams: string) {
  const params = new URLSearchParams(searchParams);

  // If UTM data is already in session storage, use existing data
  const utmSessionStorage = sessionStorage.getItem(UTM_SESSION_STORAGE_KEY);
  if (utmSessionStorage !== null) {
    return;
  }

  // If no UTM data in session storage, store new data
  // UTM data is a set of 5 parameters defined in UTM_PARAMS above. If a value exists for a given param, store that value, otherwise store null for that param
  const utmData: UtmDataType = Object.keys(UTM_PARAMS).reduce((acc, key) => {
    const utmKey = key as keyof typeof UTM_PARAMS;
    const data = params.get(UTM_PARAMS[utmKey]);
    acc[utmKey] = data;
    return acc;
  }, {} as UtmDataType);

  sessionStorage.setItem(UTM_SESSION_STORAGE_KEY, JSON.stringify(utmData));
}
